import React from "react";
import { Modal, Button } from "antd";
import "./CollectionPopup.scss";
import { useRouter, useSearchParams } from "next/navigation";

const CollectionDescriptionPopup = ({ title, longDescription }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const isOpen = searchParams.get("popup") === "true";

  const handleClose = () => {
    // Create a new URLSearchParams instance
    const newSearchParams = new URLSearchParams(searchParams.toString());
    // Remove the popup parameter
    newSearchParams.delete("popup");

    // Use the router to navigate to the same page without the popup parameter
    router.replace(`${window.location.pathname}?${newSearchParams.toString()}`);
  };

  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={handleClose}
      footer={[
        <Button key="close" type="primary" onClick={handleClose}>
          Close
        </Button>,
      ]}
      className="collection-description-popup"
      destroyOnClose
    >
      <div
        className="collection-description-content"
        dangerouslySetInnerHTML={{ __html: longDescription }}
      />
    </Modal>
  );
};

export default CollectionDescriptionPopup;
