"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import NextImage from "next/image";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Tag, Col, Card, Space, Typography, Image } from "antd";

//? Assets --------------------------------------------------------------------->
import "./ProductCard.scss";
import DefaultImage from "../../../../assets/images/defaultImages/alfa-loader.gif";
import WishlistIcon from "../../../../assets/icons/addToWishListIcon.svg";
import BagIcon from "../../../../assets/icons/bag-icon.svg";
import { removeCommasAndConvertToNumber } from "../../../../functions/HelperFunctions/index";
import FetchSingleProductModal from "@/components/ProductPage/FetchSingleProductModal";

//? Library Constants ---------------------------------------------------------->
const { Meta } = Card;
const { Text } = Typography;

const ProductCard = ({
  keyIndex,
  product,
  type,
  userAgent,
  megaSearchMenuCheck,
  categoryName,
  // onQuickShopClick,
}) => {
  const [hoverProduct, setHoverProduct] = useState(false);
  const [currencyName, setCurrencyName] = useState();
  // const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    product?.images[0] + "%3Fwidth%3D440"
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProductHandle, setSelectedProductHandle] = useState(null);
  useEffect(() => {
    if (product?.handle) {
      setImageSrc(product?.images[0] + "%3Fwidth%3D440");
    }
  }, [product]);

  const onQuickShopClick = (handle) => {
    setSelectedProductHandle(handle);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedProductHandle(null);
  };
  const { isDesktop, isTablet, isMobile } = userAgent;

  const defaultCurrency = useSelector(
    (state) => state.multiLocation.defaultCurrency
  );
  const cookiesCurrency = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");

  const CD_status = useMemo(
    () => product?.features?.find((feature) => feature?.name === "CD Status"),
    [product?.features]
  );

  const DeliveryTime = useMemo(
    () =>
      product?.features?.find((feature) => feature?.name === "Delivery Time"),
    [product?.features]
  );

  let collectionName = categoryName
    ? categoryName
    : Cookies?.get("collection_name")
    ? Cookies?.get("collection_name")
    : product?.category[0]?.title ?? "";

  function extractDateAfterHyphen(deliveryDateString) {
    const parts = deliveryDateString?.split(" - ");
    return parts?.length > 1 ? parts[1] : null;
  }

  const deliveryDate = product?.delivery_date;
  const dateAfterHyphen = extractDateAfterHyphen(deliveryDate);

  function parseDate(dateString) {
    if (!dateString) {
      console.error("Invalid date string:", dateString);
      return null;
    }

    const parts = dateString.replace(",", "").trim().split(" ");
    if (parts.length !== 3) {
      console.error("Date string does not match expected format:", dateString);
      return null;
    }

    const day = parseInt(parts[0], 10);
    const monthStr = parts[1];
    const year = parseInt(parts[2], 10);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = monthNames.indexOf(monthStr);

    return new Date(Date.UTC(year, monthIndex, day));
  }

  function currentFormatDate() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    return `${day} ${month}, ${year}`;
  }

  function isDateWithin10DaysOfDelivery(currentDateStr, deliveryDateStr) {
    const currentDate = parseDate(currentDateStr);
    const deliveryDate = parseDate(deliveryDateStr);

    if (!currentDate || !deliveryDate) {
      console.error("Invalid date(s) provided");
      return false;
    }

    const diffTime = Math.abs(currentDate.getTime() - deliveryDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 10;
  }

  const currentDateStr = currentFormatDate();

  useEffect(() => {
    if (cookiesCurrency) {
      setCurrencyName(cookiesCurrency);
    } else if (defaultCurrency) {
      setCurrencyName(defaultCurrency);
    }
  }, [defaultCurrency, cookiesCurrency]);

  const discountPriceMargin = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(originalPrice - discountedPrice);
      return `${percentageOff}`;
    }

    return "";
  };

  const googleAnalytics = () => {
    if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
      if (typeof window !== "undefined" && window?.dataLayer) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: "select_item",
          ecommerce: {
            item_list_name: collectionName,
            items: [
              {
                item_id: product?.id,
                item_name: product?.title,
                index: keyIndex,
                price: removeCommasAndConvertToNumber(
                  product?.variant_detail?.discounted_price
                ),
                item_brand: product?.brand?.[0]?.title ?? "No Brand",
                item_category: collectionName ? collectionName : undefined,
                item_variant: product?.variant_detail?.variant_id,
                location_id: defaultgeoLocationCountry,
              },
            ],
          },
        });
      }
    }
  };

  const onMouseOverHandler = () => {
    if (product?.images?.length > 1) {
      setImageSrc(product.images[1] + "%3Fwidth%3D440");
      // setHoverProduct(true);
    }
  };

  const onMouseOutHandler = () => {
    setImageSrc(product?.images[0] + "%3Fwidth%3D440" ?? DefaultImage);
    // setHoverProduct(false);
  };

  const handleImageError = () => {
    setImageSrc(product.images[0] || DefaultImage.src);
  };

  const imageStyle = {
    width: "100%",
    height: isMobile ? "195px" : "300px",
    objectFit: "contain",
    transition: "transform 0.6s ease-in-out",
    transform: hoverProduct ? "scale(1.02)" : "scale(1)",
  };

  // const onImageLoad = () => {
  //   setImageLoaded(true);
  // };

  return (
    <Col className="product_card" style={{ width: "100%" }}>
      <Link href={"/" + product?.handle}>
        {/* <div className="wishlist_icon-main">
          <NextImage
            className="wishlist_icon"
            src={WishlistIcon}
            alt="Wishlist Icon"
            width={20}
            height={20}
          />
        </div> */}
        <Card
          hoverable
          className="product-card-inner"
          onClick={() => googleAnalytics()}
          cover={
            <>
              {product?.sold_out ? (
                <Col
                  className={
                    !isMobile || isTablet
                      ? "outOfStock_container"
                      : "outOfStock_container mobile_outOfStock_container"
                  }
                >
                  <Text
                    className={
                      !isMobile || isTablet
                        ? "outOfStock_tag flexCenter"
                        : "outOfStock_tag flexCenter font12"
                    }
                  >
                    Out Of Stock
                  </Text>
                </Col>
              ) : null}
              {/* {!imageLoaded ? (
                <div
                  style={{
                    width: "1px",
                    height: isMobile ? "207px" : "300px",
                    top: 0,
                    left: 0,
                    zIndex: 4,
                  }}
                />
              ) : null} */}
              <div
                style={{
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  width="100%"
                  objectFit="contain"
                  height={isMobile ? "195px" : "300px"}
                  alt={product?.title}
                  // blurDataURL={
                  //   product?.images?.length ? product?.images[0] : DefaultImage
                  // }
                  // onLoad={onImageLoad}
                  onError={handleImageError}
                  src={imageSrc}
                  style={imageStyle}
                  // onMouseOut={onMouseOutHandler}
                  // onMouseOver={onMouseOverHandler}
                  preview={false}
                />
              </div>
            </>
          }
        >
          <Meta
            title={
              <Text
                className={
                  !isDesktop
                    ? type !== "homepage"
                      ? "mobile_product_title  product_title_two_lines"
                      : "mobile_product_title_homepage font12"
                    : type !== "homepage"
                    ? "product_title product_title_two_lines"
                    : "product_title product_title_homepage"
                }
              >
                {product?.title}
              </Text>
            }
            description={
              <Space
                direction="vertical"
                className="width100 ant-card-meta-description-bottom"
              >
                <Space className="flexBetween product-card-price-container">
                  <Space>
                    {removeCommasAndConvertToNumber(
                      product?.variant_detail?.discounted_price
                    ) <
                      removeCommasAndConvertToNumber(
                        product?.variant_detail?.original_price
                      ) && (
                      <>
                        <Text className="price-cut">
                          <span>{currencyName + " "}</span>
                          {/* {discountPriceMargin()} */}
                          {product?.variant_detail?.original_price}
                        </Text>
                        {/* <Tag
                        className={
                          !isMobile || isTablet
                            ? "discount_tag"
                            : "mobile_discount_tag"
                        }
                      >
                        <Text className={isMobile || (isTablet && "font11")}>
                          {discountPriceMargin > 1
                            ? discountPriceMargin()
                            : discountPriceMargin()}
                        </Text>
                      </Tag> */}
                      </>
                    )}
                    {product?.variant_detail?.discounted_price && (
                      <Text
                        className={
                          !isDesktop ? "product_price_mobile " : "product_price"
                        }
                      >
                        <span>{currencyName + " "}</span>
                        <span>{product?.variant_detail?.discounted_price}</span>
                      </Text>
                    )}
                  </Space>
                </Space>
              </Space>
            }
          />
          {/* <div className="quick-shop_icon-main cursorPointer">
            <NextImage
              className="quick-shop_icon"
              src={BagIcon}
              alt="shop Icon"
              width={20}
              height={20}
            // onClick={() => {
            //   onQuickShopClick(product?.handle);
            // }}
            />
          </div> */}
        </Card>
      </Link>
      {isModalVisible && (
        <FetchSingleProductModal
          handle={selectedProductHandle}
          userAgent={userAgent}
          storeId={23}
          defaultCookiesCurrencyValue="PKR"
          defaultCookiesCountryValue="PK"
          visible={isModalVisible}
          onClose={handleModalClose}
        />
      )}
    </Col>
  );
};

export default ProductCard;
