"use client";
//? Library Imports ------------------------------------------------------------>
import axios from "axios";
import NextImage from "next/image";

import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useEffect, useState, useRef, useMemo } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import {
  Row,
  Col,
  Space,
  Button,
  Select,
  Typography,
  Pagination,
  Spin,
  // Image,
  Tag,
  Drawer,
  Radio,
  Image,
} from "antd";
import { CloseCircleFilled, FilterOutlined } from "@ant-design/icons";
//? Assets --------------------------------------------------------------------->
import InfiniteScroll from "react-infinite-scroll-component";
import "./CollectionPage.scss";
import { FaFilter } from "react-icons/fa";
import robot from "../../assets/images/defaultImages/robot.jpg";
import GridViewIcon from "../../assets/Icons/grid-view-icon.svg";
import ListViewIcon from "../../assets/Icons/list-view-icon.svg";
import AlfaLoader from "../../assets/images/alfa-loader.gif";

// import TopSellerIcon from "../../assets/images/collection page/sort icons/";
import SortIcon from "../../assets/Icons/sort-icon.svg";
//? Modules -------------------------------------------------------------------->
import FiltersDrawer from "./FiltersDrawer";
import BreadCrumbs from "../Shared/BreadCrumbs/BreadCrumbs";
import FiltersDrawerMobileAlfa from "./FiltersDrawerMobileAlfa";
import ProductCard from "../Shared/ProductsCarousel/ProductCard/ProductCard";
import ProductHorizontalCard from "../Shared/ProductsCarousel/ProductCard/ProductHorizontalCard/ProductHorizontalCard";
import {
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../redux/Slices/multiLocationSlice";
import CustomSkeletonBox from "../CustomSkeletonBox/CustomSkeletonBox";
import useBrowserBackButton from "../globalBackButton/BackButton";
import ScrollToTopComponent from "../scrollToTopHook/ScrollToTop";
import { removeCommasAndConvertToNumber } from "@/functions/HelperFunctions";
import { useRouter } from "next/navigation";
import LandingPage from "@/components/CollectionPage/LandingPage";
import SortDrawer from "./MobileSortDrawer";
import MobileTopNav from "../shared/Header/MobileTopNav/MobileTopNav";
import VideoPlayer from "../VideoBanner/LazyVideoPlayer";
import DetailNoteModal from "../Homepage/DiscountModal/DetailsNoteModal";
import CollectionDescriptionPopup from "./CollectionPopup";
//? Library Constants ---------------------------------------------------------->
const { Text, Title } = Typography;

export default function CollectionPage({
  landingPageDataServer,
  handle,
  pageType,
  userAgent,
  filtersData,
  productsList,
  totalProducts,
  storeId,
  collectionData,
  clientDetail,
  defaultCookiesCurrencyValue,
  defaultCookiesCountryValue,
  next_cursor,
  prev_cursor,
  url_has_cursor,
  itemListName,
  itemListID,
  serverSortOption,
}) {
  const router = useRouter();
  if (Cookies.get("defaultCurrencyId") !== "23") {
    Cookies.set("defaultCurrencyId", storeId, {
      expires: 365,
    });
  }
  if (Cookies.get("defaultCurrencyName") !== "PKR") {
    Cookies.set("defaultCurrencyName", defaultCookiesCurrencyValue, {
      expires: 365,
    });
  }
  if (Cookies.get("defaultCountry") !== "Pakistan") {
    Cookies.set("defaultCountry", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  if (Cookies.get("geoLocation") !== "Pakistan") {
    Cookies.set("geoLocation", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  if (handle === "sky-tower-branch" && Cookies.get("user_type") !== "islamic") {
    Cookies.set("user_type", "islamic", {
      expires: 365,
    });
    location?.reload();
  }
  const defaultCurrencyIdFromCookies =
    storeId || Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");

  const [products, setProducts] = useState(productsList);
  const [productsListingDetail, setProductsListingDetail] = useState({
    count: totalProducts,
  });
  const [resetFilterFlag, setResetFilterFlag] = useState(false);
  const [pageCursor, setpageCursor] = useState({
    next: null,
    previous: null,
  });
  const [loading, setLoading] = useState(false);
  const [pageNumberSeo, setpageNumberSeo] = useState(1);
  const [allFilters, setAllFilters] = useState(filtersData);
  const [cursor, setCursor] = useState(2);
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [productsLimit, setProductsLimit] = useState(24);
  const [sortOption, setSortOption] = useState(serverSortOption || "newest");
  // const [sortOption, setSortOption] = useState("");
  const [OrderOption, setOrderOption] = useState("asc");
  const { isDesktop, isMobile } = userAgent;
  const [gridView, setGridView] = useState(isDesktop ? 4 : 2);
  const [showDropDownValues, setShowDropDownValue] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFiltersList, setSelectedFiltersList] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState(storeId);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [clientApi, setClientApi] = useState(false);
  const [collectionPayload, setCollectionPayload] = useState(null);
  const [sortUpdateCounter, setSortUpdateCounter] = useState(1);
  const [collectionDataClient, setCollectionDataClient] = useState(null);
  const [viewCategory, setViewCategory] = useState(true);
  const [callEvent, setCallEvent] = useState(false);
  const [landingPageData, setLandingPageData] = useState(landingPageDataServer);
  const topElementRef = useRef(null);
  const [filterPriceValue, setFilterPriceValue] = useState({});
  const [openDiscountModal, setopenDiscountModal] = useState(false);
  const searchParams = useSearchParams();
  const showPopup = searchParams.get("popup");

  const [brandBannerImage, setBrandBannerImage] = useState(
    collectionData?.banner_image
  );
  const pathname = usePathname();
  useEffect(() => {
    if (products?.length < productsLimit || !products) {
      setCursor(null);
    }
  }, [products]);

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const sortLabels = {
    newest: "Newest",
    price_asc: "Price (Low to High)",
    price_des: "Price (High to Low)",
    discounts: "Top Discounts",
    sellers: "Top Sellers",
    manual: "Handpicked",
  };

  useBrowserBackButton(() => {});

  let sortQueryValue = sortOption;
  let defaultCountryId;

  // useEffect(() => {
  //   if (window.history.pushState) {
  //     const url = new URL(window.location);
  //     url.searchParams.delete("cursor");
  //     window.history.pushState({ path: url.toString() }, "", url.toString());
  //   }
  // }, []);
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };

  const initializeData = async () => {
    try {
      const geoLocationUrl =
        "https://pro.ip-api.com/json/?fields=61439&key=r8KxA4oLfB365n8";
      const countryDataUrl = `${process.env.NEXT_PUBLIC_BACKEND}/storefront/store_list`;

      const apiData = await fetchData(geoLocationUrl);

      const countryListData = await fetchData(countryDataUrl);

      const matchingCountry = countryListData?.country_list.find(
        (countryItem) => countryItem.country === apiData.country
      );
      if (matchingCountry) {
        Cookies.set("defaultCurrencyName", matchingCountry.currency, {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", matchingCountry.id, {
          expires: 365,
        });

        setDefaultCountry(matchingCountry.id);
        defaultCountryId = matchingCountry.id;

        Cookies.set("defaultCountry", matchingCountry.country, {
          expires: 365,
        });

        Cookies.set("geoLocation", matchingCountry.country, {
          expires: 365,
        });

        dispatch(setDefaultCountry(matchingCountry));
        dispatch(changeCountryId(matchingCountry.id));
        dispatch(changeCountry(matchingCountry.country));
        dispatch(changeCountryCode(matchingCountry.short_code));
        dispatch(changeCurrency(matchingCountry.currency));
      } else {
        Cookies.set("defaultCurrencyName", "USD", {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", "35", {
          expires: 365,
        });
        setDefaultCountry("35");
        Cookies.set("defaultCountry", "United States", {
          expires: 365,
        });

        Cookies.set("geoLocation", apiData.country, {
          expires: 365,
        });
        defaultCountryId = "35";
        dispatch(setDefaultCountry("United States"));
        dispatch(changeCountryId("35"));
        dispatch(changeCountry("United States"));
        dispatch(changeCurrency("USD"));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (pathname === "/premier") {
      const urlParams = new URLSearchParams(window.location.search);
      const hasReloaded = urlParams.get("reloaded");

      if (!hasReloaded && Cookies.get("user_type") !== "premier") {
        Cookies.set("user_type", "premier", {
          expires: 365,
        });
        window.location.href = `${pathname}?reloaded=true`;
      } else if (hasReloaded) {
        const cleanUrl = window.location.href.split("?")[0];
        window.history.replaceState({}, document.title, cleanUrl);
      }
    }
  }, [pathname]);

  useEffect(() => {
    const fetchDataAndInitialize = async () => {
      await initializeData();

      await fetchProducts(activePage, productsLimit, handle, false);

      await getFilters(pageType, handle);
    };

    if (storeId === null || !defaultCurrencyIdFromCookies) {
      fetchDataAndInitialize();
      scrollToTop();
      setLoading(true);
    }

    if (!window?.location?.href.includes("?")) {
      const newPath = `${window?.location?.origin}/${handle}`;
      history.pushState({}, "", newPath);
    }
  }, []);

  useEffect(() => {
    if (collectionData) {
      // setSortOption(collectionData?.sort);
      setCollectionDataClient(collectionData);
      setBrandBannerImage(collectionData?.banner_image);
      if (!window?.location?.href.includes("?")) {
        getFilters();
      }
    }
    if (clientApi) {
      fetchProducts(activePage, productsLimit, handle, false);

      getFilters(pageType, handle);
    }
  }, []);

  const fetchProducts = async (
    page,
    pageSize,
    sortValue,
    cursorNullFlag,
    clearFilterFlag
  ) => {
    let sortQueryString = "";
    let finalParam =
      process.env.NEXT_PUBLIC_BACKEND + "/storefront/product_list?";

    // if (OrderOption) {
    // } else {
    //   sortQueryString = "&sort=" + sortQueryValue;
    //   console.log(sortQueryString, "sortQueryString");
    // }
    // debugger
    let queryParas = window.location.search.split("?")[1];
    let cursorParam = null;
    let appendUrl = `${pageType}_handle=${handle}`;
    if (cursor && !cursorNullFlag) {
      cursorParam = cursor;
    }

    if (!queryParas?.includes("limit")) {
      finalParam += `limit=${productsLimit}&`;
    }

    finalParam +=
      appendUrl +
      sortQueryString +
      `&storefront=${defaultCountryId ?? defaultCurrencyIdFromCookies}`;

    if (cursorParam && clearFilterFlag === undefined) {
      if (queryParas) {
        if (queryParas.includes("page=")) {
          queryParas = queryParas.split("page")[0];
          finalParam += `&${queryParas}` + `page=${cursorParam}`;
        } else {
          finalParam += `&${queryParas}` + `&page=${cursorParam}`;
        }
      } else {
        finalParam += `&page=${cursorParam}`;
      }

      if (window.location.search.includes("page")) {
        let splittedValue = window.location.search;
        splittedValue = splittedValue.split("page")[0];
        if (
          window.location.search.includes("size") ||
          window.location.search.includes(
            "price" ||
              window.location.search.includes("color") ||
              window.location.search.includes("brand") ||
              window.location.search.includes("hide_out_of_stock")
          )
        ) {
          window.history.pushState(
            null,
            null,
            splittedValue + `page=${cursorParam}`
          );
        } else {
          window.history.pushState(
            null,
            null,
            window.location.pathname + splittedValue + `page=${cursorParam}`
          );
        }
      } else {
        if (
          window.location.search.includes("size") ||
          window.location.search.includes(
            "price" ||
              window.location.search.includes("color") ||
              window.location.search.includes("brand") ||
              window.location.search.includes("hide_out_of_stock")
          )
        ) {
          window.history.pushState(
            null,
            null,
            window.location.pathname +
              window.location.search +
              `&page=${cursorParam}`
          );
        } else {
          if (resetFilterFlag) {
            window.history.pushState(null, null, window.location.pathname);
            setResetFilterFlag(false);
          } else {
            if (queryParas) {
              window.history.pushState(
                null,
                null,
                window.location.pathname + `?${queryParas}&page=${cursorParam}`
              );
            } else {
              window.history.pushState(
                null,
                null,
                window.location.pathname + `?page=${cursorParam}`
              );
            }
          }
        }
      }
    } else {
      if (clearFilterFlag) {
        setResetFilterFlag(false);
      }
      finalParam += `&${queryParas}`;
    }

    axios
      .get(finalParam)
      .then((response) => {
        setCallEvent(true);
        if (!cursorNullFlag) {
          let prevProducts = products;
          let newProducts = response?.data?.data;
          let mergedProducts = [...prevProducts, ...newProducts];
          setProducts(mergedProducts);
        } else {
          scrollToTop();
          setProducts(response?.data?.data);
        }
        let cursorObject = {
          next: null,
          previous: null,
        };
        let splitterPrev = null;
        let splitterNext = null;
        // debugger
        if (cursorNullFlag) {
          setCursor(2);
        } else if (response?.data?.data?.length > 0) {
          setCursor(cursor + 1);
        } else {
          setCursor(null);
        }
        // if (response?.data?.data?.previous) {
        //   cursorObject.previous = (response?.data?.data?.previous)
        //     .split("cursor=")[1]
        //     .split("&")[0];
        // } else {
        //   cursorObject.previous = null;
        // }

        setpageCursor({ ...cursorObject });

        // if (response?.data?.data?.next) {
        //   setCursor(response?.data?.data?.next);
        //   if (clearFilterFlag) {
        //     setpageNumberSeo(1);
        //   } else {
        //     setpageNumberSeo(pageNumberSeo + 1);
        //   }
        // } else {
        //   setCursor(null);
        // }
        setProductsListingDetail({
          title: response?.title,
          count: response?.data?.data?.count,
        });
        if (sortUpdateCounter === 1 && !storeId) {
          setCollectionDataClient(response?.data);
          // setSortOption(response?.data?.sort);
          setSortUpdateCounter(2);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // useEffect(() => {
  //   if (clientApi) {
  //     <ScrollToTopComponent />;
  //     setLoading(true);
  //   }
  // }, [clientApi]);

  // useEffect(() => {
  //   scrollToTop()
  // }, []);

  const getFilters = async () => {
    let queryParas = window.location.search.split("?")[1];
    let appendUrl = queryParas
      ? `${pageType}_handle=${handle}` + "&" + queryParas
      : `${pageType}_handle=${handle}`;

    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
          "/storefront/filter_list?" +
          appendUrl +
          `&storefront=${defaultCountryId ?? defaultCurrencyIdFromCookies}`
      )

      .then((response) => {
        setAllFilters(response.data);

        // Assuming response.data is the array you provided
        const priceObj = response.data.find((item) => item.type === "price");

        if (priceObj) {
          const price = {
            min: priceObj.min,
            max: priceObj.max,
          };
          setFilterPriceValue(price);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSortChange = (value) => {
    if (defaultCountryId !== null) {
      setClientApi(true);
      setSortOption(value);
      setActivePage(1);
      scrollToTop();
      setLoading(true);
      setCursor(null);
      setProducts([]);
    }
  };

  useEffect(() => {
    if (sortOption && clientApi) {
      handleFiltersQuery();
    }
    console.log("sortOptionsortOption", sortOption);
  }, [sortOption]);

  useEffect(() => {
    setShowDropDownValue([
      { value: "24", label: "24" },
      { value: "48", label: "48" },
      { value: "72", label: "72" },
    ]);
  }, []);

  const onChangeGridView = (gridValue) => {
    setGridView(gridValue);
    if (gridValue === 2 || gridValue === -1) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
    if (gridValue === 3) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
    if (gridValue === 4) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
    if (gridValue === 5) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
  };

  const appendToUrl = (query) => {
    let urlToAppend = "";

    if (query !== "") {
      urlToAppend = "?" + query.slice(1);
    } else {
      urlToAppend = handle;
    }

    window.history.pushState(null, null, urlToAppend);

    router.push(urlToAppend, undefined, { shallow: true });
    if (defaultCountry !== null) {
      fetchProducts(activePage, productsLimit, undefined, true);
      setpageNumberSeo(1);
      getFilters();
    }
  };

  const clearFilters = () => {
    setResetFilterFlag(true);
    let newUrl = window.location.href.split("?");
    window.history.replaceState({}, document.title, newUrl[0]);
    setActivePage(1);
    if (defaultCountryId !== null) {
      scrollToTop();
      setLoading(true);
      fetchProducts(activePage, productsLimit, undefined, true, true);
      getFilters();
    }
  };

  const handleChangeShowItems = (value) => {
    if (defaultCountryId !== null) {
      setProductsLimit(value);
      setCursor(null);
      setProducts([]);
      handleSelectedFilter(false, "limit", value);
    }
  };

  const showFiltersDrawer = () => {
    setShowFilters(true);
  };

  const handleSelectedFilter = (e, name, value) => {
    scrollToTop();
    setLoading(true);
    setClientApi(true);
    setCursor(null);
    let tempSelectedFilters = selectedFilters;
    let presentFlag = false;
    let pagePresentFlag = false;

    if (name !== "price" && name !== "page" && name !== "limit") {
      if (e.target.checked === true) {
        for (let index = 0; index < tempSelectedFilters.length; index++) {
          if (tempSelectedFilters[index]?.type === "page") {
            pagePresentFlag = true;
          }
        }

        if (pagePresentFlag) {
          for (let index = 0; index < tempSelectedFilters.length; index++) {
            if ("page" === tempSelectedFilters[index].type) {
              tempSelectedFilters[index].value = [1];
            }
          }
        }

        for (let index = 0; index < tempSelectedFilters.length; index++) {
          if (name === tempSelectedFilters[index].type) {
            presentFlag = true;
          }
        }

        if (presentFlag) {
          for (let index = 0; index < tempSelectedFilters.length; index++) {
            if (name === tempSelectedFilters[index].type) {
              tempSelectedFilters[index].value.push(value);
            }
          }
        } else {
          tempSelectedFilters.push({
            type: name,
            value: [value],
          });
        }
      } else {
        for (let index = 0; index < tempSelectedFilters.length; index++) {
          //
          if (name === tempSelectedFilters[index].type) {
            tempSelectedFilters[index].value.splice(
              tempSelectedFilters[index].value.indexOf(value),
              1
            );
          }
        }
      }
    } else if (name === "page" || name === "limit") {
      for (let index = 0; index < tempSelectedFilters.length; index++) {
        if (name === tempSelectedFilters[index].type) {
          presentFlag = true;
        }
      }

      if (presentFlag) {
        for (let index = 0; index < tempSelectedFilters.length; index++) {
          if (name === tempSelectedFilters[index].type) {
            tempSelectedFilters[index].value = [value];
          }
        }
      } else {
        tempSelectedFilters.push({
          type: name,
          value: [value],
        });
      }
    } else {
      let priceRangePresent = false;
      if (tempSelectedFilters.length) {
        tempSelectedFilters?.map((filter) => {
          if (name === filter.type) {
            filter.value = [value];
            priceRangePresent = true;
          }
        });
      }
      if (!priceRangePresent) {
        tempSelectedFilters.push({
          type: name,
          value: [value],
        });
      }
    }
    setSelectedFilters(tempSelectedFilters);
    handleFiltersQuery();
  };

  const handleFiltersQuery = () => {
    let query = "";
    for (let index = 0; index < selectedFilters.length; index++) {
      if (selectedFilters[index].value.length) {
        query += "&";
        query += selectedFilters[index].type + "=";
        query += selectedFilters[index].value.join("%2B");
      }
    }

    if (sortOption) {
      query += `&sort=${sortOption}`;
    }

    appendToUrl(query + totalPrice);
  };

  const handleSelectedFiltersUrl = () => {
    let currentUrl = window.location.href;

    let urlToProcess = currentUrl.split("?")[1];
    if (urlToProcess) {
      let urlToProcessList = urlToProcess.split("&");

      let tempSelectedFilters = [];

      urlToProcessList?.map((filter) => {
        //
        let filterType = filter.split("=")[0];
        let filterValue = filter.split("=")[1]?.split("%2B");

        if (
          filterType !== "page" &&
          filterType !== "limit" &&
          filterType !== "sort"
        ) {
          tempSelectedFilters.push({
            type: filterType,
            value: filterValue,
          });
        } else {
          if (filterType === "page") {
            setActivePage(parseInt(filterValue[0]));
          }
          if (filterType === "limit") {
            setProductsLimit(parseInt(filterValue[0]));
          }
          if (filterType === "sort") {
            setSortOption(filterValue[0]);
          }
        }
      });

      setSelectedFilters(tempSelectedFilters);
      getFilters();
    }
  };
  const capitalizeEachWord = (text) => {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const scrollToTop = () => {
    // if (topElementRef.current) {
    //   topElementRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const formattedHandle = capitalizeEachWord(handle ?? "");

  useEffect(() => {
    if (defaultCountry !== null) {
      handleSelectedFiltersUrl();
    }

    // scrollToTop()
  }, []);
  const handleCollectionTitle = (str) => {
    if (str && str?.includes("/")) {
      return str.split("/").pop();
    } else {
      return str;
    }
  };
  function processCollectionData(results) {
    const processedData = results.map((item, index) => {
      return {
        item_name: item?.title,
        item_id: item?.id,
        price: removeCommasAndConvertToNumber(
          item?.variant_detail?.discounted_price
        ),
        item_brand: item?.brand?.[0]?.title ?? "No Brand",
        item_category: itemListName,
        item_variant: item?.variant_detail?.variant_id,
        index: index,
        location_id: defaultCookiesCountryValue,
      };
    });

    return processedData;
  }
  useEffect(() => {
    if (!Cookies.get("collection_name")) {
      Cookies.set("collection_name", itemListName);
      Cookies.set("landing_from", itemListName);
    }
  });
  useEffect(() => {
    if (products && callEvent) {
      console.log("running");
      Cookies.set("collection_name", itemListName);
      Cookies.set("landing_from", itemListName);
      const processedResponse = processCollectionData(products);
      if (viewCategory) {
        fbq("track", "ViewCategory", {
          content_category: collectionDataClient?.title,
        });
        setViewCategory(false);
      }

      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          item_list_id: itemListID,
          item_list_name: itemListName,
          currencyCode: defaultCurrencyFromCookies
            ? defaultCurrencyFromCookies
            : "PKR",
          items: processedResponse,
        },
      });
    }
  }, [products, callEvent]);
  useEffect(() => {
    if (!window.location.href.includes("?")) {
      setCallEvent(true);
    }
  }, []);

  useEffect(() => {}, [selectedFilters]);

  // const handleDelete = (filterType, filterValue) => {
  //   const updatedFilters = selectedFilters.map(filter => {
  //     if (filter.type === filterType) {
  //       return {
  //         ...filter,
  //         value: filter.value.filter(value => value !== filterValue)
  //       };
  //     }
  //     return filter;
  //   }).filter(filter => filter.value.length > 0);

  //   setSelectedFilters(updatedFilters);
  // };

  const handleClearAll = () => {
    setSelectedFilters([]);
    clearFilters();
  };
  const showDiscountModal = (flag) => {
    setopenDiscountModal(flag);
  };
  // useEffect(() => {
  //   if (pathname === "/hunarmand") {
  //     showDiscountModal(true);
  //   }
  // }, []);

  console.log(collectionData, "landingPageData");

  return (
    <>
      <div
        className={
          landingPageData
            ? "landing_page_wrap"
            : "collection_page_wrapper collection_page_wrapper_mobile"
        }
      >
        {isDesktop ? (
          <>
            {pathname === "/women-fahion" && (
              <VideoPlayer videoURL="https://alfa-cdn.comverseglobal.com/alfa/products/product_images/deepak-perwan-1.mp4" />
            )}
            {pathname === "/men-fashion" && (
              <VideoPlayer videoURL="https://alfa-cdn.comverseglobal.com/alfa/products/product_images/amir-adnan.mp4" />
            )}
          </>
        ) : null}
        {landingPageData && (
          <div className="landing-page-content">
            <LandingPage homePageData={landingPageData} userAgent={userAgent} />
          </div>
        )}
        <>
          {loading && !landingPageData && brandBannerImage && !isMobile ? (
            <CustomSkeletonBox width="95%" height={400} />
          ) : (
            brandBannerImage &&
            !isMobile && (
              <div className="collection-banner">
                <Image
                  height="auto"
                  width="100%"
                  preview={false}
                  src={brandBannerImage}
                />
              </div>
            )
          )}
        </>
        {!landingPageData && (
          <div className="collection-page-inner">
            {isDesktop &&
              pathname !== "/women-fahion" &&
              pathname !== "/men-fashion" && (
                <div className="collection-page-left">
                  <div className="collection-page-left-inner">
                    <div className="breadcrumb">
                      <BreadCrumbs
                        type={
                          pageType === "category"
                            ? " Category "
                            : pageType === "collection"
                            ? " Collection "
                            : pageType === "brand"
                            ? " Brand "
                            : null
                        }
                        data={collectionDataClient}
                        title={formattedHandle}
                        userAgent={userAgent}
                      />
                    </div>
                    <div className="filter-drawer">
                      {allFilters && (
                        <FiltersDrawer
                          allFilters={allFilters}
                          setAllFilters={setAllFilters}
                          appendToUrl={appendToUrl}
                          clearFilters={clearFilters}
                          showFilters={showFilters}
                          setShowFilters={setShowFilters}
                          setSelectedFiltersList={setSelectedFiltersList}
                          getFilters={getFilters}
                          fetchProducts={fetchProducts}
                          activePage={activePage}
                          handleSelectedFilter={handleSelectedFilter}
                          selectedFilters={selectedFilters}
                          setSelectedFilters={setSelectedFilters}
                          totalPrice={totalPrice}
                          setCursor={setCursor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            {allFilters && (
              <FiltersDrawerMobileAlfa
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                appendToUrl={appendToUrl}
                clearFilters={clearFilters}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                setSelectedFiltersList={setSelectedFiltersList}
                getFilters={getFilters}
                fetchProducts={fetchProducts}
                activePage={activePage}
                handleSelectedFilter={handleSelectedFilter}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                totalPrice={totalPrice}
                setCursor={setCursor}
              />
            )}
            <SortDrawer
              visible={visible}
              onClose={onClose}
              onSortChange={onSortChange}
              sortOption={sortOption}
            />

            <div className="collection-page-right">
              <div className="collection-page-right-inner">
                <div className="collection-page-header for-desktop">
                  <div className="collection-page-header-left">
                    <p>
                      {" "}
                      {products?.length} items in <span> {itemListName} </span>
                    </p>
                  </div>
                  <div className="collection-page-header-right">
                    <div className="sort-by">
                      <Select
                        style={{ width: 164 }}
                        onChange={onSortChange}
                        defaultValue={sortOption}
                        className="sort_select_option"
                        value={sortOption}
                        options={[
                          {
                            value: "price_asc",
                            label: "Price (Low to High)",
                          },
                          {
                            value: "price_des",
                            label: "Price (High to Low)",
                          },
                          { value: "newest", label: "Newest" },
                          { value: "discounts", label: "Top Discounts" },
                          { value: "sellers", label: "Top Sellers" },
                          { value: "manual", label: "Handpicked" },
                        ]}
                      />
                    </div>
                    <div className="grid-icons">
                      <div
                        className={`grid-view${
                          gridView === 4 ? " active" : null
                        }`}
                        onClick={() => onChangeGridView(4)}
                      >
                        <img src={GridViewIcon.src} />
                      </div>
                      <div
                        className={`list-view${
                          gridView === -1 ? " active" : null
                        }`}
                        onClick={() => onChangeGridView(-1)}
                      >
                        <img src={ListViewIcon.src} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collection-page-header for-mobile">
                  {isMobile ? (
                    <Row style={{ width: "95vw" }}>
                      <MobileTopNav
                        title={
                          pathname.includes("fahion") ? null : formattedHandle
                        }
                        bagButton={true}
                      />
                    </Row>
                  ) : null}
                  {/* <h3> {itemListName} </h3> */}
                  <Row style={{ width: "96%" }}>
                    <div className="collection-page-header-left">
                      <div className="sort-by" onClick={showDrawer}>
                        <label> Sort: {sortLabels[sortOption]} </label>
                        <img src={SortIcon.src} />
                      </div>
                    </div>
                    <div className="collection-page-header-right">
                      <div
                        className="filter-selection-container"
                        onClick={showFiltersDrawer}
                      >
                        {/* <label> Filter <span> (3) </span>  </label> */}
                        <label> Filter </label>
                        <FilterOutlined />
                      </div>
                      <div className="grid-icons">
                        <div
                          className={`grid-view${
                            gridView === 2 ? " active" : null
                          }`}
                          onClick={() => onChangeGridView(2)}
                        >
                          <img src={GridViewIcon.src} />
                        </div>
                        <div
                          className={`list-view${
                            gridView === -1 ? " active" : null
                          }`}
                          onClick={() => onChangeGridView(-1)}
                        >
                          <img src={ListViewIcon.src} />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                <div className="collection-page-selected-filters">
                  <div className="filters-container">
                    {selectedFilters.map(
                      (filter) =>
                        filter.type !== "sort" &&
                        filter.value.map((value, index) => (
                          <Tag
                            key={`${filter.type}-${index}`}
                            closable
                            onClose={(e) => {
                              // if (filter.type === "price") {
                              //   handleSelectedFilter(null, filter.type, filterPriceValue?.min + "-" + filterPriceValue?.max);
                              // }
                              // else {
                              //   handleSelectedFilter(e, filter.type, value)
                              // }
                              // handleSelectedFilter(null, filterType, defaultMin + "-" + defaultMax);
                              handleSelectedFilter(e, filter.type, value);
                            }}
                            className="filter-tag"
                          >
                            {value}
                          </Tag>
                        ))
                    )}
                    {selectedFilters.length > 0 && (
                      <Button
                        onClick={handleClearAll}
                        className="clear-all-button"
                      >
                        Clear all filter
                      </Button>
                    )}
                  </div>
                </div>
                <div className="main-grid">
                  {loading ? (
                    <div style={{ display: "flex" }}>
                      {Array.from({ length: gridView }, (_, index) => (
                        <div key={index} style={{ flex: 1, marginRight: 8 }}>
                          <CustomSkeletonBox
                            width="100%"
                            height={isMobile ? 300 : 450}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      {isMobile ? (
                        <>
                          {pathname === "/women-fahion" && (
                            <VideoPlayer videoURL="https://alfa-cdn.comverseglobal.com/alfa/products/product_images/deepak-perwan-1.mp4" />
                          )}
                          {pathname === "/men-fashion" && (
                            <VideoPlayer videoURL="https://alfa-cdn.comverseglobal.com/alfa/products/product_images/amir-adnan.mp4" />
                          )}
                        </>
                      ) : null}
                      <>
                        {loading &&
                        !landingPageData &&
                        brandBannerImage &&
                        isMobile ? (
                          <CustomSkeletonBox width="95%" height={150} />
                        ) : (
                          brandBannerImage &&
                          isMobile && (
                            <div className="collection-banner">
                              <Image
                                height="auto"
                                width="100%"
                                preview={false}
                                src={brandBannerImage}
                              />
                            </div>
                          )
                        )}
                      </>
                      <InfiniteScroll
                        dataLength={products?.length ? products?.length : 0}
                        next={fetchProducts}
                        hasMore={cursor}
                        loader={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height: "100px",
                            }}
                          >
                            {/* <Spin /> */}
                            <img src={AlfaLoader.src} />
                          </div>
                        }
                        endMessage={
                          products?.length > 0 && (
                            <p
                              className="infinite-scroll-message"
                              style={{
                                textAlign: "center",
                                fontSize: "16px",
                                paddingBottom: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              No more results.
                            </p>
                          )
                        }
                        scrollThreshold={0.7}
                      >
                        <Row justify="center">
                          <Col lg={24}>
                            {products?.length ? (
                              <Row
                                className={
                                  !isMobile
                                    ? "product_listing_container"
                                    : " product_listing_container "
                                }
                                justify={isMobile && "space-between"}
                                gutter={[0, 10]}
                                // style={{ border: "1px solid pink" }}
                              >
                                {products?.length
                                  ? products.map((pro, index) => {
                                      return (
                                        <Col
                                          key={index}
                                          flex={
                                            gridView === 5
                                              ? "19%"
                                              : gridView === 4
                                              ? "24%"
                                              : gridView === 3
                                              ? "32.33%"
                                              : gridView === 2
                                              ? "49%"
                                              : gridView === -1
                                              ? "100%"
                                              : "70%"
                                          }
                                          style={{
                                            maxWidth:
                                              gridView === 5
                                                ? "19%"
                                                : gridView === 4
                                                ? "24%"
                                                : gridView === 3
                                                ? "32.33%"
                                                : gridView === 2
                                                ? "49%"
                                                : gridView === -1
                                                ? "100%"
                                                : "70%",
                                          }}
                                        >
                                          {gridView !== -1 ? (
                                            <ProductCard
                                              product={pro}
                                              keyIndex={index}
                                              userAgent={userAgent}
                                              pageType={"collectionPage"}
                                            />
                                          ) : (
                                            <ProductHorizontalCard
                                              product={pro}
                                              key={index}
                                              userAgent={userAgent}
                                              pageType={"collectionPage"}
                                            />
                                          )}
                                        </Col>
                                      );
                                    })
                                  : null}
                              </Row>
                            ) : (
                              <Col span={24}>
                                <Row
                                  style={{
                                    gap: "10px",
                                    overflow: "hidden",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <Col span={24}>
                                    <NextImage
                                      src={robot}
                                      alt="No Products Found!"
                                      preview={false}
                                      width={200}
                                      style={{ display: "inline-block" }}
                                    />
                                    <Text
                                      style={{
                                        display: "block",
                                        textAlign: "center",
                                        fontSize: "18px",
                                      }}
                                    >
                                      No Products Found!
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Col>
                        </Row>
                      </InfiniteScroll>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {openDiscountModal && (
          <DetailNoteModal
            showDiscountModal={showDiscountModal}
            openDiscountModal={openDiscountModal}
          />
        )}
      </div>
      {showPopup && collectionData?.long_description && (
        <CollectionDescriptionPopup
          title={
            collectionData.title || collectionData.name || "Collection Details"
          }
          longDescription={collectionData.long_description}
        />
      )}
    </>
  );
}
